import * as React from "react"
import Layout from "../components/layout"

export default function IndexPage() {
  return (
    <Layout>
      <div className="flex h-screen">
        <div className="px-4 py-8 m-auto w-full lg:w-1/2">
          <div className="text-4xl lg:text-6xl mb-4">Nyårssupé</div>
          <div className="text-xl">
            <br />
            <p class="mb-8">
              <p class="mb-2">FÖRDRINK</p>
              <b>Mousserande vin</b>
            </p>
            <p>
              <p class="mb-2">FÖRRÄTT</p>
              <b>Lax- och skaldjurstartar</b>
              <br />
              serveras med bröd & grönsallad
            </p>
            <p class="mb-8">
              <p class="mb-2">HUVUDRÄTT</p>
              <p>
                <b>Oxfilémedaljonger</b>
                <br />
                med rosépepparsås & grön sparris
                <br />
                <div class="text-base">alternativt</div>
                <p>
                  <b>Rödingfilé</b>
                  <br />
                  med champagnesås, röd tångcaviar & kräftstjärtar
                </p>
              </p>
            </p>
            <p class="mb-8">
              <p class="mb-2">DESSERT</p>
              <b>Mandeltårta</b>
              <br />
              serveras med frukt & grädde
            </p>
            <b>519 kr</b>
            <br />
          </div>
        </div>
      </div>
    </Layout>
  )
}
